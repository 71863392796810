import React from 'react';
import Link from '../components/Link/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const Error: React.FC = () => {
    return (
        <div className="error-page vh-100 text-inf flex flex-column justify-content-center align-items-center text-center">
            <div className="error-content">
                <h1 className="display-1 text-9xl text-danger fs-bolder italic font-monospace">404</h1>
                <p className="lead"><em>Oops! The page you&apos;re looking for doesn&apos;t exist.</em></p>
                <Link to="/" className="btn bg-inf hover:bg-green-500 p-3 text-light rounded-5 m-3 no-underline">
                    <FontAwesomeIcon icon={faHome} /> Go to Homepage
                </Link>
                <button onClick={() => window.history.back()} className="btn bg-sec border-pri hover:border-light hover:bg-gray-900 hover:text-inf p-3 rounded-5 m-3">
                    <FontAwesomeIcon icon={faArrowLeft} /> Last Window
                </button>
            </div>
        </div>
    );
};

export default Error;