import CurriculumSection from '../CurriculumSection/CurriculumSection';
import { curriculum } from '../data/curriculum';

const TechStackSection: React.FC = () => (
    <section id='tech-stack' className='md:pt-5 animate-slideInBottom md:px-[4%] text-start mt-2 md:mt-5 md:mb-[100px] '>
        <h2 className='text-5xl font-PolySans font-extrabold py-3 text-sec'>Tools & Softwares</h2>
        <p className='font-Playwrite text-2xl col-md-7 mb-5'>Over the years, I had the opportunity to work with various software, tools and frameworks. Here are some of them:</p>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-3 text-2xl mx-auto md:mx-0'>
            {curriculum.map((section, index) => (
                <CurriculumSection key={index} index={index} {...section} />
            ))}
        </div>
    </section>
);

export default TechStackSection;