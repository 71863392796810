import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCode, faLaptopCode, faCogs, faDatabase, faServer, faProjectDiagram, faClipboardList, faLightbulb } from '@fortawesome/free-solid-svg-icons';

export type CurriculumSectionType = {
    category: string;
    icon: IconDefinition;
    items: string[];
};

export const curriculum: CurriculumSectionType[] = [
    {
        category: 'Programming Languages',
        icon: faCode,
        items: ['C', 'Python', 'JavaScript', 'SQL', 'Bash/Shell Scripting']
    },
    {
        category: 'Development Tools',
        icon: faLaptopCode,
        items: ['Git and GitHub', 'Vagrant', 'VirtualBox', 'Vim', 'Nano', 'GCC', 'Make']
    },
    {
        category: 'Frameworks and Libraries',
        icon: faCogs,
        items: ['Flask', 'Express.js', 'React.js', 'Bootstrap', 'jQuery', 'Next.js']
    },
    {
        category: 'Databases',
        icon: faDatabase,
        items: ['MySQL', 'MongoDB', 'SQLite']
    },
    {
        category: 'Operating Systems and Networking',
        icon: faServer,
        items: ['Linux/Unix', 'TCP/IP Networking']
    },
    {
        category: 'Algorithms and Data Structures',
        icon: faProjectDiagram,
        items: ['Sorting and Searching Algorithms', 'Linked Lists', 'Stacks and Queues', 'Trees and Graphs']
    },
    {
        category: 'Software Engineering Principles',
        icon: faClipboardList,
        items: ['Agile Methodologies', 'Test-Driven Development (TDD)', 'Object-Oriented Programming (OOP)', 'Model-View-Controller (MVC) Architecture', 'RESTful API Design']
    },
    {
        category: 'Soft Skills',
        icon: faLightbulb,
        items: ['Technical Writing', 'Problem-Solving', 'Team Collaboration', 'Project Management']
    }
];
