import Image from '../Image/Image';

const MainImage: React.FC = () => (
    <>
        <Image src={'/imgs/img11.png'} alt={'dev-img'} width={500} height={400}
               className='animate-floatF position-absolute hidden md:block md:top-[250px] md:right-[150px]'/>
        <Image src={'/imgs/img11.png'} alt={'dev-img'} width={400} height={400} layout='responsive'
               className='animate-floatF position-absolute w-75 top-[350px] right-[40px] md:hidden'/>
    </>
);

export default MainImage;