import LinkItem from '../LinkItem/LinkItem';
import {links} from '../data/links';
import React from "react";

const Footer: React.FC = () => (
    <>
        <hr className='border-3 shadow-glow-pri rounded-5 mt-[100px] border-suc'/>

        <footer className='my-24 md:my-0 md:mt-24 p-[2%] md:px-[4%]'>
            <div className='text-left font-playwrite text-light'>
                <h2 className='text-4xl font-extrabold mb-7 font-PolySans'>Okeomasilachi Onyedibia</h2>
                <p className='mb-14 font-Playwrite col-md-4 col-12'>Forward thinking Software Engineer / Full Stack Web Developer. I thrive on creating
                    innovative solutions</p>
                <div className='grid md:flex grid-cols-2 gap-4 md:gap-0'>
                    {links.map((link, index) => (
                        index !== 4 && <LinkItem external className="font-mono underline" key={index} {...link} />
                    ))}
                </div>
                <p className='font-Playwrite font-thin mt-4 md:mt-0'>All rights reserved &copy; Okeomasilachi
                    Onyedibia {new Date().getFullYear()}</p>
            </div>
        </footer>
    </>
);

export default Footer;