const Header: React.FC = () => (
    <section className='flex flex-col align-items-center space-y-5 md:mt-5 my-[60px] md:my-[170px] md:mb-[350px]'>
        <h2 className='animate-slideInTop font-PolySans text-inf font-extrabold text-5xl md:text-8xl w-100 z-1 text-center'>Okeomasilachi Onyedibia</h2>
        <span className='animate-slideInRight font-Playwrite leading-snug z-1 font-extrabold capitalize text-sec text-2xl md:text-6xl w-100 text-center'>Software Engineer & Full-Stack Web Developer</span>
        <div className='col-12 col-md-6 text-2xl leading-normal md:leading-loose font-Playwrite md:ml-[100px] animate-slideInLeft mt-[100px] pt-[250px] md:pt-28 z-1 align-self-start'>
            I&apos;m passionate about <span className="inline-block px-2 fa-bounce font-Fira">🛠️</span> developing robust systems,
            <span className="inline-block px-2 fa-flip font-Fira">♿️</span> ensuring accessibility,
            <span className="inline-block px-2 fa-spin font-Fira">⚙️</span> designing efficient state machines, and
            <span className="inline-block px-2 fa-beat-fade font-Fira">💡</span> enhancing user experience.
        </div>
    </section>
);

export default Header;
