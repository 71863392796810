import React, {useEffect, useState} from 'react';
import axios from 'axios';

interface LanguageProficiency {
    language: string;
    bytes: number;
    percentage: number;
}

// Define a type for the user data
interface User {
    login: string;
    name: string;
    avatar_url: string;
    bio: string;
}

const GitLanguagePro: React.FC<{ username: string }> = ({username}) => {
    const [user, setUser] = useState<User | null>(null);
    const [proficiency, setProficiency] = useState<LanguageProficiency[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                // Fetch user data
                const userResponse = await axios.get(`https://api.github.com/users/${username}`);
                setUser(userResponse.data);

                // Fetch user repositories
                const reposResponse = await axios.get(userResponse.data.repos_url);

                const languagePromises = reposResponse.data.map(async (repo: any) => {
                    const languagesResponse = await axios.get(repo.languages_url);
                    return languagesResponse.data;
                });

                const languagesData = await Promise.allSettled(languagePromises);

                const languageTotals: { [key: string]: number } = {};

                languagesData.forEach(result => {
                    if (result.status === 'fulfilled') {
                        const languages = result.value;
                        for (const [language, bytes] of Object.entries(languages)) {
                            if (!languageTotals[language]) {
                                languageTotals[language] = 0;
                            }
                            languageTotals[language] += bytes as number;
                        }
                    }
                });

                const totalBytes = Object.values(languageTotals).reduce((acc, curr) => acc + curr, 0);

                const proficiencyData = Object.entries(languageTotals).map(([language, bytes]) => ({
                    language,
                    bytes,
                    percentage: (bytes / totalBytes) * 100
                }));

                // Sort the proficiency data by percentage in descending order
                proficiencyData.sort((a, b) => b.percentage - a.percentage);

                setProficiency(proficiencyData);
            } catch (error) {
                console.error('Error fetching GitHub data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [username]);

    if (loading) {
        // return <h2 className="text-2xl md:text-6xl text-sec font-bold text-center font-Playwrite">Loading...</h2>;
        return <></>;
    }

    if (!user) {
        return <div></div>;
    }

    const languageColors: { [key: string]: string } = {
        Python: '#3572A5',
        HTML: '#e34c26',
        CSS: '#563d7c',
        Shell: '#89e051',
        JavaScript: '#f1e05a',
        TypeScript: '#2b7489',
        C: '#555555',
        PowerShell: '#012456',
        Ruby: '#701516',
        Puppet: '#302B6D',
        Swift: '#ffac45',
        Perl: '#0298c3',
        Makefile: '#427819',
    };

    return (
        <section id='git-pro'
                 className='animate-slideInBottom flex flex-col md:flex-row md:px-14 justify-content-stretch align-items-stretch md:space-x-5 md:mt-5 my-[200px] md:mb-[200px]'>
            <div className='w-100'>
                <h2 className='text-sec text-center mb-5 font-PolySans text-5xl'>Bytes Written</h2>
                <ul className='grid grid-cols-2 pt-5 md:grid-cols-3 gap-5'>
                    {proficiency.map((lang, index) => (
                        <li className='font-Playwrite' key={index}>
                            {lang.language}: {lang.bytes} bytes ({lang.percentage.toFixed(2)}%)
                            <div className='rounded-l-full rounded-r-full'
                                 style={{width: '100%', backgroundColor: '#f0f0f0', marginTop: '5px'}}>
                                <div className='rounded-l-full rounded-r-full' style={{
                                    width: `${lang.percentage}%`,
                                    height: '20px',
                                    backgroundColor: languageColors[lang.language] || '#000000'
                                }}></div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
}

export default GitLanguagePro;
