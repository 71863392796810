import React from 'react';
import MainImage from "../components/MainImage/MainImage";
import Header from "../components/Header/Header";
import AboutSection from "../components/AboutSection/AboutSection";
import TechStackSection from "../components/TechStackSection/TechStackSection";
import GitLanguagePro from "../components/GitLanguagePro/GitLanguagePro";
// import Footer from "../components/Footer/Footer";

function Home() {
    return (
        <>
            <MainImage/>
            <Header/>
            <AboutSection/>
            <GitLanguagePro username={'okeomasilachi'}/>
            <TechStackSection/>
        </>
    );
}

export default Home;
