// src/components/CustomImage.tsx
import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';

type Layout = 'fill' | 'responsive' | 'intrinsic' | 'fixed';

interface CustomImageProps {
    src: string;
    alt: string;
    width?: number;
    height?: number;
    className?: string;
    placeholderSrc?: string;
    layout?: Layout;
}

const getImageStyle = (layout: Layout, width?: number, height?: number): React.CSSProperties => {
    switch (layout) {
        case 'fill':
            return { position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' };
        case 'responsive':
            return { width: '100%', height: 'auto' };
        case 'intrinsic':
            return { width: `${width}px`, height: `${height}px` };
        case 'fixed':
            return { width: `${width}px`, height: `${height}px` };
        default:
            return {};
    }
};

const Image: React.FC<CustomImageProps> = ({
                                                     src,
                                                     alt,
                                                     width,
                                                     height,
                                                     className,
                                                     placeholderSrc,
                                                     layout = 'intrinsic',
                                                 }) => {
    const [loaded, setLoaded] = useState(false);
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const imageStyle = getImageStyle(layout, width, height);

    return (
        <div
            ref={ref}
            style={{ width: layout === 'responsive' ? '100%' : width, height: layout === 'fill' ? '100%' : height, position: 'relative' }}
            className={`overflow-hidden ${className}`}
        >
            {!loaded && placeholderSrc && (
                <img
                    src={placeholderSrc}
                    alt={alt}
                    style={{ ...imageStyle, filter: 'blur(10px)' } as React.CSSProperties}
                    className="absolute top-0 left-0 object-cover"
                />
            )}
            {inView && (
                <img
                    src={src}
                    alt={alt}
                    style={{ ...imageStyle, opacity: loaded ? 1 : 0, transition: 'opacity 0.5s' } as React.CSSProperties}
                    className="absolute top-0 left-0 object-cover"
                    onLoad={() => setLoaded(true)}
                />
            )}
        </div>
    );
};

export default Image;
