import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import Link from "../components/Link/Link";
import Image from '../components/Image/Image';

// Define a type for the project data
interface Project {
    title: string;
    github: string;
    website?: string;
    description: string;
    image: string;
    languages: { [key: string]: number };
}

const repos: string[] = [
    'FarmCon',
    'alx-files_manager',
    'printf',
    'monty',
    'sorting_algorithms',
    'alx-higher_level_programming',
    'alx-low_level_programming',
    'AirBnB_clone_v4',
    'AirBnB_clone_v3',
    'AirBnB_clone_v2',
    'AirBnB_clone',
];

const languageColors: { [key: string]: string } = {
    'JavaScript': '#f1e05a',
    'TypeScript': '#2b7489',
    'Python': '#3572A5',
    'HTML': '#e34c26',
    'CSS': '#563d7c',
    'Shell': '#89e051',
    'C': '#fdfcfc',
    'C++': '#f34b7d',
    // Add more languages and colors as needed
};

const Projects: React.FC = () => {
    const [projects, setProjects] = useState<Project[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const handleRefresh = () => {
        window.location.reload(); // Reloads the current page
      };

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const projectPromises = repos.map(async (repo): Promise<Project> => {
                    try {
                        const repoResponse = await axios.get(`https://api.github.com/repos/okeomasilachi/${repo}`);
                        const languagesResponse = await axios.get(repoResponse.data.languages_url);

                        console.log(`Fetched data for ${repo}:`, repoResponse.data);

                        return {
                            title: repoResponse.data.name,
                            github: repoResponse.data.html_url,
                            website: repoResponse.data.homepage,
                            description: repoResponse.data.description,
                            image: '/imgs/img4.jpg', // Replace with logic to fetch images if needed
                            languages: languagesResponse.data,
                        };
                    } catch (error) {
                        console.error(`Error fetching data for ${repo}:`, error);
                        throw error;
                    }
                });

                const results = await Promise.allSettled(projectPromises);
                const fetchedProjects: Project[] = results
                    .filter(result => result.status === 'fulfilled')
                    .map(result => (result as PromiseFulfilledResult<Project>).value);

                console.log('Fetched projects:', fetchedProjects);

                setProjects(fetchedProjects);
            } catch (error) {
                console.error('Error fetching GitHub repos:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProjects();
    }, []);

    if (loading) {
        return <h2 className="text-2xl md:text-6xl text-sec font-bold text-center font-Playwrite">Loading...</h2>;
    }

    if (projects.length === 0) {
        return <div className='flex flex-col justify-content-center px-5'>
            <Image src='/imgs/oops.png' className='hidden md:block animate-slideInTop align-self-center' width={500} height={400} alt='oops'/>
            <Image src='/imgs/oops.png' className='md:hidden animate-slideInTop align-self-center' width={200} height={200} alt='oops'/>
            <h2 className='text-center my-5 text-2xl italic font-Playwrite text-sec'>Error Loading the projects</h2>
            <button className='btn btn-outline-light  rounded-5 mx-auto px-5 py-3 shadow-glow-pri' onClick={handleRefresh}>Refresh Page</button>
        </div> 
    }

    const calculateLanguagePercentages = (languages: { [key: string]: number }) => {
        const total = Object.values(languages).reduce((sum, bytes) => sum + bytes, 0);
        return Object.entries(languages).map(([language, bytes]) => ({
            language,
            percentage: ((bytes / total) * 100).toFixed(2),
        }));
    };

    return (
        <>
            <h2 className="text-3xl md:text-6xl text-sec font-bold text-center font-Playwrite">Projects</h2>
            <div className="grid grid-cols-1 gap-4 md:p-[6%]">
                {projects.map((project, index) => (
                    <div key={index}
                         className="grid grid-cols-1 md:grid-cols-2 p-4 rounded-lg w-100 my-5 border-2 shadow-glow-pri rounded-5 border-in">
                        <div className='pt-3'>
                            <h2 className="text-2xl md:text-5xl text-wrap overflow-ellipsis font-bold text-sec mt-2 font-PolySans">{project.title}</h2>
                            <div className='my-3 my-md-5 space-x-14'>
                                {project.github &&
                                  <Link to={project.github} external className='text-inf'>
                                    <FontAwesomeIcon icon={faGithub} className="mr-2 text-3xl"/> GitHub
                                  </Link>
                                }
                                {project.website &&
                                  <Link to={project.website} external className='text-inf'>
                                    <FontAwesomeIcon icon={faGlobe} className="mr-2 text-3xl"/> Website
                                  </Link>
                                }
                            </div>
                            <p className="text-light mt-2 mr-6 font-serif leading-[1.5] text-2xl">{project.description}</p>
                            <div className="language-progress mt-3 mr-5">
                                <div className='rounded-r-full rounded-l-full' style={{ height: '10px', backgroundColor: '#ddd', borderRadius: '5px', display: 'flex' }}>
                                    {calculateLanguagePercentages(project.languages).map((lang, idx) => (
                                        <div className='rounded-r-full rounded-l-full' key={idx} style={{
                                            width: `${lang.percentage}%`,
                                            backgroundColor: languageColors[lang.language] || '#000',
                                            height: '100%',
                                            borderRadius: idx === 0 ? '5px 0 0 5px' : idx === project.languages.length - 1 ? '0 5px 5px 0' : '0'
                                        }} title={`${lang.language}: ${lang.percentage}%`}></div>
                                    ))}
                                </div>
                                <div className="flex flex-wrap mt-2">
                                    {calculateLanguagePercentages(project.languages).map((lang, idx) => (
                                        <span key={idx} className="mr-2" style={{ color: languageColors[lang.language] || '#000' }}>
                                            {lang.language}: {lang.percentage}%
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='bg-gradient-to-r from-sec mt-4 md:mt-0 to-suc pt-4 pl-5 rounded-4'>
                            <img src={project.image} alt={`${project.title}-image`}
                                 className='rounded-tl-2xl rounded-br-2xl h-full'/>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export default Projects;
