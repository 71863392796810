import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

type CurriculumSectionProps = {
    category: string;
    icon: IconDefinition;
    items: string[];
    index: number;
};

const CurriculumSection: React.FC<CurriculumSectionProps> = ({ category, icon, items , index}) => (
    <div key={index} className='py-7 px-3 border-2 shadow-glow-pri rounded-5 border-inf'>
        <h4 className='text-sec mb-4 flex align-items-center'><FontAwesomeIcon icon={icon} className="mr-4 text-inf border-1 p-2 shadow-glow-pri border-inf rounded-circle" />{category}</h4>
        <div className={`${[3, 6, 7].includes(index) ? 'ml-[55px]' : 'ml-[65px]'} grid grid-cols-1 md:grid-cols-2 gap-1`}>
            {items.map((item, index) => (
                <p key={index} className='text-base my-1'>{item}</p>
            ))}
        </div>
    </div>
);

export default CurriculumSection;