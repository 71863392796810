import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faXTwitter, faGithub } from '@fortawesome/free-brands-svg-icons';

export type LinkItemType = {
    name: string;
    href: string;
    animate: string;
    icon: IconDefinition;
};

export const links: LinkItemType[] = [
    { name: 'Linkedin', href: 'https://www.linkedin.com/in/okeomasilachi/', animate: 'fa-shake', icon: faLinkedin },
    { name: 'Twitter (X)', href: 'https://x.com/okeomasilachi1', animate: 'fa-beat', icon: faXTwitter },
    { name: 'GitHub', href: 'https://github.com/okeomasilachi', animate: 'fa-spin', icon: faGithub },
    { name: 'Email', href: 'mailto:okeomasilachi@gmail.com', animate: 'fa-flip', icon: faEnvelope }, // Update with your email address
    { name: 'Resume', href: 'https://www.linkedin.com/in/okeomasilachi/', animate: 'fa-bounce', icon: faFileAlt },
];
