import Link from "../Link/Link";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

type LinkItemProps = {
    href: string;
    animate: string;
    icon: IconDefinition;
    name: string;
    className: string;
    external?: boolean;
};

const LinkItem: React.FC<LinkItemProps> = ({ className, href, animate, icon, name, external }) => (
    <Link to={href} external={external} className="flex items-center space-x-2">
        <FontAwesomeIcon className={`text-sec ${animate}`} icon={icon} />
        <span className={`${className} text-base text-inf`}>{name}</span>
    </Link>
);

export default LinkItem;
