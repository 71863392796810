import Image from "../Image/Image";
import LinkItem from '../LinkItem/LinkItem';
import { links } from '../data/links';


const AboutSection: React.FC = () => (
    <section id='tech-stack' className='font-playwrite animate-slideInBottom flex flex-col md:flex-row justify-content-center align-items-center md:space-x-5 md:mt-5 my-[200px] md:mb-[200px]'>
        <div className='col-12 col-md-6 font-playwrite text-pri position-relative font-extrabold text-5xl md:text-7xl flex flex-col align-items-start md:align-items-center'>
            <Image className='animate-float rounded-circle w-[20%] position-absolute hidden md:inline-block md:top-[10px] border-3 md:left-[0px] bottom-[100px] right-[10px] shadow-glow-pri'
                   src='/me.jpeg' alt={'Okeomasilachi Onyedibia'}
                   width={100} height={100} />
            <h2 className='text-inf text-5xl md:text-8xl md:ml-32 font-PolySans font-extrabold'>I Develop</h2>
            <span className='text-sec md:py-5 font-PolySans'> and Engineer Solutions</span>
        </div>
        <div className='col-12 col-md-5 text-2xl flex flex-col justify-content-center align-items-start justify-start space-y-6'>
            <div className='md:text-justify leading-loose font-Playwrite justify-self-start align-self-start font-light'>
                As a software engineer with a comprehensive background in programming, development tools,
                frameworks, and design principles, I specialize in creating robust and scalable software
                solutions.
            </div>
            <div className='col-12 flex flex-col font-mono align-items-start md:justify-content-between space-y-9'>
                <div className='md:flex grid grid-cols-2 gap-x-5 md:gap-x-6 gap-y-8 md:grid-cols-4'>
                    {links.map((link, index) => (
                        index !== 2 && <LinkItem external className='underline font-Playwrite mr-4' key={index} {...link} />
                    ))}
                </div>
            </div>
        </div>
    </section>
);

export default AboutSection;
