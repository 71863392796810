import React from 'react';
import Navbar from './components/Nav/Nav'
import {Outlet} from "react-router-dom";
import Footer from "./components/Footer/Footer";

function App() {
    return (
        <div className="App">
            <main
                className="font-mono md:mt-[90px] container min-h-screen flex-col text-light items-center position-relative">
                <Navbar/>
                <Outlet/>
                <Footer/>
            </main>
        </div>
    );
}

export default App;
