import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// eslint-disable-next-line
import {faBars, faFolderOpen, faMicrochip, faTimes, faHome} from '@fortawesome/free-solid-svg-icons';
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Image from '../Image/Image'; // Replace with your Image component implementation


// Component for desktop navigation links
const DesktopNavLinks: React.FC = () => {
    const navigate = useNavigate()
    const location = useLocation();

    // Function to determine if a link is active
    const isActive = (href: string) => {
        return location.pathname + location.hash === href;
    };

    const menuItems = [
        {
            text: "Projects",
            route: "/projects",
            icon: faFolderOpen,
        },
        // {
        //     text: "Technologies",
        //     route: "/#tech-stack",
        //     icon: faMicrochip,
        // },
        // {
        //     text: "Remarks",
        //     route: "/remarks",
        //     icon: faComment,
        // },
        // {
        //     text: "Requests",
        //     route: "/requests",
        //     icon: faEnvelopeOpen,
        // }
    ];

    return (
        <ul className="flex flex-grow justify-end space-x-6 text-inf no-underline">
            {menuItems.map((item, index) => (
                <li key={index}
                    className={`text-light italic font-mono ${isActive(item.route) ? 'text-pri font-semibold' : 'hover:text-sec hover:font-semibold'}`}>
                    <button
                        className={isActive(item.route) ? "px-3 py-2 rounded-2 border-1 shadow-glow-pri bg-bgd border-sec text-inf font-extrabold hover:font-semibold" : "h-full text-lime-100 hover:text-sec hover:font-semibold"}
                        onClick={() => navigate(item.route)}
                    >
                        <FontAwesomeIcon icon={item.icon}
                                         className={`${isActive(item.route) ? "text-sec" : "text-sec"} mr-2 hover:text-lime-100 `}/>
                        {item.text}
                    </button>
                </li>
            ))}
        </ul>
    );
};

// Component for the logo section
const LogoLink: React.FC = () => {
    const navigate = useNavigate()

    return (
        <button onClick={() => navigate('/')} className="flex items-center">
            <Image width={50} height={50} src="https://avatars.githubusercontent.com/u/110942635?v=4"
                   className="rounded-5 hidden md:inline-block border-sec w-7 border-3 shadow-glow-pri" alt='Logo'/>
            <Image width={35} height={35} src="https://avatars.githubusercontent.com/u/110942635?v=4"
                   className="rounded-5 md:hidden border-sec w-7 border-3 shadow-glow-pri" alt='Logo'/>
        </button>
    );
};

// Component for the mobile menu button
const MobileMenuButton: React.FC<{ onClick: () => void }> = ({onClick}) => {
    return (
        <button className="absolute right-[8px] top-0 p-2.5 hover:text-pri" onClick={onClick}>
            <FontAwesomeIcon className=" text-light hover:text-pri" icon={faBars}/>
        </button>
    );
};

// Component for the mobile drawer
const MobileDrawer: React.FC<{ isOpen: boolean, onClose: () => void }> = ({isOpen, onClose}) => {
    const navigate = useNavigate()
    const location = useLocation();

    // Function to determine if a link is active
    const isActive = (href: string) => {
        return location.pathname === href;
    };

    const handleLinkClick = (href: string) => {
        navigate(href);
        onClose(); // Close the mobile drawer
    };

    return (
        <div
            className={`inset-0 bg-gradient-to-r from-sec to-suc fixed flex flex-col justify-center items-center z-10 top-0 right-0 h-full w-full bg-white text-black transition-transform duration-300 transform ${
                isOpen ? 'translate-x-0' : 'translate-x-full'
            }`}
        >
            <button className="absolute right-7 top-4 text-light p-3" onClick={onClose}>
                <FontAwesomeIcon className="text-5xl " icon={faTimes}/>
            </button>
            <ul className="flex flex-col justify-center items-center space-y-4 no-underline">
                <li className={`${isActive('/') ? 'text-pri font-semibold' : 'text-gray-700 hover:text-sec hover:font-extrabold'}`}>
                    <button className='no-underline text-light'
                            onClick={() => handleLinkClick('/')}>Home<FontAwesomeIcon icon={faHome}
                                                                                                  className="ml-3 text-pri"/>
                    </button>
                </li>
                <li className={`${isActive('/projects') ? 'text-pri font-semibold' : 'text-gray-700 hover:text-sec hover:font-extrabold'}`}>
                    <button className='no-underline text-light'
                            onClick={() => handleLinkClick('/projects')}>Projects<FontAwesomeIcon icon={faFolderOpen}
                                                                                                  className="ml-3 text-pri"/>
                    </button>
                </li>
                {/*<li className={`italic font-mono ${isActive('/#tech-stack') ? 'text-pri font-semibold' : 'text-gray-700 hover:text-sec hover:font-extrabold'}`}>*/}
                {/*    <button className='no-underline text-light'*/}
                {/*            onClick={() => handleLinkClick('/#tech-stack')}>Technologies<FontAwesomeIcon*/}
                {/*        icon={faMicrochip} className="ml-3 text-pri"/></button>*/}
                {/*</li>*/}
                {/*<li className={`italic font-mono ${isActive('/remarks') ? 'text-pri font-semibold' : 'text-gray-700 hover:text-sec hover:font-extrabold'}`}>*/}
                {/*    <button className='no-underline text-light' onClick={() => handleLinkClick('/remarks')}>Remarks<FontAwesomeIcon icon={faComment} className="ml-3 text-pri" /></button>*/}
                {/*</li>*/}
                {/*<li className={`italic font-mono ${isActive('/requests') ? 'text-pri font-semibold' : 'text-gray-700 hover:text-dark hover:font-extrabold'}`}>*/}
                {/*    <button className='no-underline text-light' onClick={() => handleLinkClick('/requests')}>Requests<FontAwesomeIcon icon={faEnvelopeOpen} className="ml-3 text-pri" /></button>*/}
                {/*</li>*/}
            </ul>
        </div>
    );
};

// Header component
const Header: React.FC = () => {
    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            if (currentScrollY > lastScrollY) {
                // Scroll down
                setIsVisible(false);
            } else {
                // Scroll up
                setIsVisible(true);
            }

            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    // Toggle function for opening/closing the mobile drawer
    const handleDrawerToggle = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    return (
        <>
            <div
                className={`md:px-[100px] bg-transparent backdrop-blur-sm container fixed-top hidden md:flex items-center justify-between py-6 transition-transform duration-300 ${isVisible ? 'transform translate-y-0' : 'transform -translate-y-full'}`}>
                <LogoLink/>
                <DesktopNavLinks/>
            </div>
            <div
                className={`p-2 border-b-emerald-600 md:hidden flex shadow-pri bg-transparent items-center justify-between transition-transform duration-300 ${isVisible ? 'transform translate-y-0' : 'transform -translate-y-full'}`}>
                <LogoLink/>
                <MobileMenuButton onClick={handleDrawerToggle}/>
            </div>
            <MobileDrawer isOpen={isDrawerOpen} onClose={handleDrawerToggle}/>
        </>
    );
};

export default Header;
