import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

interface CustomLinkProps extends RouterLinkProps {
    className?: string;
    prefetch?: boolean;
    external?: boolean;
}

const Link: React.FC<CustomLinkProps> = ({ className, prefetch = true, external = false, children, ...props }) => {
    return (
        <RouterLink
            className={className}
            {...props}
            target={external ? '_blank' : undefined}
            rel={external ? 'noopener noreferrer' : undefined}
            onMouseEnter={() => {
                if (prefetch) {
                    // Implement prefetching logic here if desired
                }
            }}
        >
            {children}
        </RouterLink>
    );
};

export default Link;
